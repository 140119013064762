








































































































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class StModule extends Vue {
  @Prop() private readonly data!: any
  @Prop() private readonly levels!: any
  @Prop() private readonly type!: any
  @Prop() private readonly periods!: any
  @Prop() private readonly baseLevels!: any
  @Prop() private readonly dynamicCol!: any
  @Prop() private readonly isEnd!: boolean

  private skillColor = {
    M: '#FA8C16',
    AM: '#FFA940',
    P: '#FFC069',
    AP: '#FFD591',
    BP: '#FFE7BA',
    NC: '#D9D9D9',
    E: '#FA8C16',
    G: '#FFA940',
    S: '#FFC069',
    N: '#FFD591',
  }

  private getColumns(index, type): any {
    const levels = type === 'base' ? this.baseLevels : this.levels
    let dynamicCol = this.dynamicCol?.map((item, hIndex) => {
      return index === 0
        ? {
            title: item.name,
            align: 'right',
            customHeaderCell: () => ({
              style: {
                background: '#fff',
                borderRight:
                  hIndex === this.dynamicCol.length - 1
                    ? 'solid 2px rgba(0, 0, 0, 0.15)'
                    : 'solid 1px rgba(0, 0, 0, 0.15)',
                borderLeft: 'solid 1px rgba(0, 0, 0, 0.15)',
              },
            }),
            children:
              levels?.map((level, lIndex) => {
                return {
                  title: level.abbreviation,
                  key: `${item.gradePeriodId}_${level.abbreviation}`,
                  width: 30,
                  customHeaderCell: () => ({
                    style: {
                      borderTop: `solid 4px ${this.skillColor[level.abbreviation]}`,
                      borderRight:
                        lIndex === levels.length - 1
                          ? hIndex === this.dynamicCol.length - 1
                            ? `solid 2px rgba(0, 0, 0, 0.15)`
                            : 'solid 1px rgba(0, 0, 0, 0.15)'
                          : '',
                      borderLeft: !lIndex ? `solid 1px rgba(0, 0, 0, 0.15)` : '',
                      // borderLeft: 'solid 4px #FA8C16',
                      padding: '0 0',
                      textAlign: 'center',
                    },
                  }),
                  customCell: record => ({
                    style: {
                      background:
                        record?.grades[item.gradePeriodId] === level.gradeLevelId
                          ? this.skillColor[level.abbreviation]
                          : '#fff',
                      borderRight:
                        lIndex === levels.length - 1
                          ? hIndex === this.dynamicCol.length - 1
                            ? `solid 2px rgba(0, 0, 0, 0.15)`
                            : 'solid 1px rgba(0, 0, 0, 0.15)'
                          : '',
                      borderLeft: !lIndex ? `solid 1px rgba(0, 0, 0, 0.15)` : '',
                    },
                  }),
                }
              }) || [],
          }
        : levels?.map((level, lIndex) => {
            return {
              title: level.abbreviation,
              key: `${item.gradePeriodId}_${level.abbreviation}`,
              width: 30,
              customHeaderCell: () => ({
                style: {
                  borderTop: `solid 4px ${this.skillColor[level.abbreviation]}`,
                  borderRight:
                    lIndex === levels.length - 1
                      ? hIndex === this.dynamicCol.length - 1
                        ? `solid 2px rgba(0, 0, 0, 0.15)`
                        : 'solid 1px rgba(0, 0, 0, 0.15)'
                      : '',
                  borderLeft: !lIndex ? `solid 1px rgba(0, 0, 0, 0.15)` : '',
                  padding: '0 0',
                  textAlign: 'center',
                },
              }),
              customCell: record => ({
                style: {
                  background:
                    record?.grades[item.gradePeriodId] === level.gradeLevelId
                      ? this.skillColor[level.abbreviation]
                      : '#fff',
                  borderRight:
                    lIndex === levels.length - 1
                      ? hIndex === this.dynamicCol.length - 1
                        ? `solid 2px rgba(0, 0, 0, 0.15)`
                        : 'solid 1px rgba(0, 0, 0, 0.15)'
                      : '',
                  borderLeft: !lIndex ? `solid 1px rgba(0, 0, 0, 0.15)` : '',
                },
              }),
            }
          })
    })
    if (index !== 0) {
      dynamicCol = dynamicCol.flat(1)
    }
    return [
      {
        slots: { title: index === 0 ? 'tableTitle' : 'skillTitle' },
        customHeaderCell: () => ({
          style: {
            background: index === 0 ? '#fff' : '#fafafa',
            borderRight: `solid 1px rgba(0, 0, 0, 0.15)`,
          },
        }),
        customCell: () => ({
          style: {
            borderRight: `solid 1px rgba(0, 0, 0, 0.15)`,
          },
        }),
        children:
          index === 0
            ? [
                {
                  slots: { title: 'skillTitle' },
                  scopedSlots: { customRender: 'secondSkill' },
                  customCell: () => ({
                    style: {
                      borderRight: `solid 1px rgba(0, 0, 0, 0.15)`,
                    },
                  }),
                  customHeaderCell: () => ({
                    style: {
                      borderRight: `solid 1px rgba(0, 0, 0, 0.15)`,
                    },
                  }),
                },
              ]
            : undefined,
        scopedSlots: { customRender: 'secondSkill' },
      },
      ...dynamicCol,
    ]
  }
}
