































































































































































































































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { CurriculumController } from '@/services/request.service'
import flattenDeep from 'lodash/flattenDeep'
import ReportModule from './ReportModule.vue'
import StModule from './StModule.vue'

@Component({
  components: {
    ReportModule,
    StModule,
  },
})
export default class Report extends Vue {
  @Prop() public readonly data!: any
  @Prop() public readonly type!: any
  @Prop() public readonly schoolYearType!: any
  @Prop() public readonly loaded!: boolean

  // test
  // private mounted():  void {
  //   console.log(this.data)
  // }

  private imgError: boolean = false
  private Vue = Vue

  private EffortData: any = [
    {
      name: '学习参与\nLearning Engagement',
      description:
        '模范带头作用、乐于助人、积极和始终如一地投入。\nHelpful,positive and consistently engaged.',
      key: 1,
    },
    {
      name: '学习参与\nLearning Engagement',
      description:
        '礼貌地接受反馈，并能建设性地使用反馈。\nAccepts feedback and is able to use it constructively.',
      key: 2,
    },
    {
      name: '学习参与\nLearning Engagement',
      description: "尊重别人的意见 \nRespectful of others' opinion.",
      key: 3,
    },
    {
      name: '学习参与\nLearning Engagement',
      description: '在同伴中的领导力 \nDemonstrates leadership with peers.',
      key: 4,
    },
    {
      name: '学习参与\nLearning Engagement',
      description: '坚持完成学习任务 \nStays on task and completes assignments.',
      key: 5,
    },
    {
      name: '学习参与\nLearning Engagement',
      description: '在完成任务上的谨慎和逻辑性 \nUses care and logic on tasks and assignments.',
      key: 6,
    },
    {
      name: '学习参与\nLearning Engagement',
      description:
        '当老师和同学讲话时，积极倾听 \nActively listens to teachers and fellow scholars.',
      key: 7,
    },
    {
      name: '学习参与\nLearning Engagement',
      description: '按照指示回答问题 \nFollows directions and questions.',
      key: 8,
    },
    {
      name: '学习参与\nLearning Engagement',
      description: '能够理解并提出适当的问题 \nShows understanding and asks appropriate questions.',
      key: 9,
    },
    {
      name: '学习态度\nLearning Attitude',
      description: '遵守边界和规则 \nAdheres to boundaries and rules.',
      key: 10,
    },
    {
      name: '学习态度\nLearning Attitude',
      description: '课前所需材料的准备 \nPrepared with required materials',
      key: 11,
    },
    {
      name: '学习态度\nLearning Attitude',
      description: '守时 \nGets to class on time',
      key: 12,
    },
  ]

  private GradeData: any = [
    {
      grade: 'A+',
      percent: '97-100',
      cp: '4.0',
      ecp: '4.5',
      ap: '5.0',
    },
    {
      grade: 'A',
      percent: '93-96',
      cp: '4.0',
      ecp: '4.5',
      ap: '5.0',
    },
    {
      grade: 'A-',
      percent: '90-92',
      cp: '3.7',
      ecp: '4.2',
      ap: '4.7',
    },
    {
      grade: 'B+',
      percent: '87-89',
      cp: '3.3',
      ecp: '3.8',
      ap: '4.3',
    },
    {
      grade: 'B',
      percent: '83-86',
      cp: '3.0',
      ecp: '3.5',
      ap: '4.0',
    },
    {
      grade: 'B-',
      percent: '80-82',
      cp: '2.7',
      ecp: '3.2',
      ap: '3.7',
    },
    {
      grade: 'C+',
      percent: '77-79',
      cp: '2.3',
      ecp: '2.8',
      ap: '3.3',
    },
    {
      grade: 'C',
      percent: '73-76',
      cp: '2.0',
      ecp: '2.5',
      ap: '3.0',
    },
    {
      grade: 'C-',
      percent: '70-72',
      cp: '1.7',
      ecp: '2.2',
      ap: '2.7',
    },
    {
      grade: 'D+',
      percent: '67-69',
      cp: '1.3',
      ecp: '1.8',
      ap: '2.3',
    },
    {
      grade: 'D',
      percent: '63-66',
      cp: '1.0',
      ecp: '1.5',
      ap: '2.0',
    },
    {
      grade: 'D-',
      percent: '60-62',
      cp: '1.0',
      ecp: '1.5',
      ap: '2.0',
    },
    {
      grade: 'F',
      percent: 'Below 60',
      cp: 'Below 60',
      ecp: 'Below 60',
      ap: 'Below 60',
    },
  ]

  private academicLevel: any = [
    {
      key: 'warning',
      value: 'Academic Warning',
    },
    {
      key: 'probation',
      value: 'Academic Probation',
    },
  ]

  private get commentList(): Array<any> {
    return []
  }
  private getAData(index): Array<any> {
    return (
      [
        {
          ...this.data?.attendanceInfo?.[index],
          ...this.data?.attendanceInfo?.[index]?.data,
        },
      ] || []
    )
  }

  private get SData(): Array<any> {
    return this.data?.gradeStandards || []
  }
  private get BSData(): Array<any> {
    return this.data?.baseLevels || []
  }
  private get AColumns(): Array<any> {
    return [
      {
        dataIndex: 'late',
        key: 'late',
        title: '迟到\nLate',
        scopedSlots: { customRender: 'commonBlock' },
      },
      {
        dataIndex: 'illness',
        key: 'sick',
        title: '病假\nSick',
        scopedSlots: { customRender: 'commonBlock' },
      },
      {
        dataIndex: 'absent',
        key: 'absentNo',
        title: '缺席\nAbsent',
        scopedSlots: { customRender: 'commonBlock' },
      },
      {
        dataIndex: 'personal',
        key: 'absentCL',
        title: '缺席(可谅解)\nExcused Absent',
        scopedSlots: { customRender: 'commonBlock' },
      },
      // 李沁颖 2021.10.15
      this.schoolYearType === '2020-2021' && [
        {
          dataIndex: 'holiday',
          key: 'vacation',
          title: '假期\nvacation',
          scopedSlots: { customRender: 'commonBlock' },
        },
        {
          dataIndex: 'exams',
          key: 'exam',
          title: '考试\nExams',
          scopedSlots: { customRender: 'commonBlock' },
        },
        {
          dataIndex: 'others',
          key: 'others',
          title: '其他\nOthers',
          scopedSlots: { customRender: 'commonBlock' },
        },
      ],
    ]
      .flat()
      .filter(item => item)
  }
  private get SColumns(): Array<any> {
    return [
      this.type === 'middle'
        ? {
            key: 'grade',
            title: '评分等级\nGrade',
            width: 200,
            scopedSlots: { customRender: 'grade' },
          }
        : undefined,
      {
        key: 'levelOfMastery',
        title: '精熟程度\nLevel of Mastery',
        scopedSlots: { customRender: 'LOM' },
        width: 200,
      },
      {
        dataIndex: 'description',
        key: 'DOP',
        title: '表现描述\nDescription of Performance',
      },
    ].filter(item => item)
  }
  private get BSColumns(): Array<any> {
    return [
      {
        dataIndex: 'name',
        title: '熟练程度\nLevel of Mastery',
        width: 200,
      },
      {
        dataIndex: 'description',
        key: 'DOP',
        title: '表现\nDescription of Performance',
      },
    ]
  }
  private get EffortColumns(): Array<any> {
    return [
      {
        dataIndex: 'name',
        title: 'Learning Efforts',
        width: 200,
        colSpan: 2,
        align: 'left',
        customRender: (value, row, index) => {
          const obj: any = {
            children: value,
            attrs: {
              rowSpan: 0,
            },
          }
          if (index === 9) {
            obj.attrs.rowSpan = 3
          }
          // These two are merged into above cell
          if (index === 0) {
            obj.attrs.rowSpan = 9
          }

          return obj
        },
      },
      {
        dataIndex: 'description',
        // key: 'DOP',
        // title: '表现\nDescription of Performance',
        // title: 'Learing Efforts',
        colSpan: 0,
        customRender: (value, row, index) => {
          const obj: any = {
            children: value,
            attrs: {},
          }
          return obj
        },
      },
    ]
  }
  private get LGColumns(): Array<any> {
    const renderContent = (value, row, index) => {
      const obj: any = {
        children: value,
        attrs: {},
      }
      if (index === 12) {
        obj.attrs.colSpan = 0
      }
      return obj
    }

    return [
      {
        dataIndex: 'grade',
        key: 'grade',
        title: 'Letter Grade',
        align: 'left',
      },
      {
        dataIndex: 'percent',
        key: 'percent',
        title: 'Percentage',
        align: 'left',
        customRender: renderContent,
      },
      {
        dataIndex: 'cp',
        key: 'cp',
        title: 'College Prep GPA',
        align: 'left',
        customRender: (text, row, index) => {
          if (index === 12) {
            return { children: text, attrs: { colSpan: 4 } }
          }
          return { children: text, attrs: {} }
        },
      },
      {
        dataIndex: 'ecp',
        key: 'ecp',
        title: 'Enriched CP GPA',
        align: 'left',
        customRender: renderContent,
      },
      {
        dataIndex: 'ap',
        key: 'ap',
        title: 'AP GPA',
        align: 'left',
        customRender: renderContent,
      },
    ]
  }

  private get displayStatus(): boolean {
    return (
      this.type === 'high' &&
      this.data.isEnd &&
      ['warning', 'probation'].includes(this.data.academicLevel)
    )
  }
}
