
















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ReportModule extends Vue {
  @Prop() private readonly title!: any
  @Prop() private readonly isUnit: any
}
